import React, {useState, useEffect , useMemo} from "react";
import ReactDOM from "react-dom";
import {API} from "aws-amplify";
import {Col} from "react-bootstrap";
import PayLaterMessage from "./PaypalPayLaterMessage";

let PayPalButton, loadScript = null
function CustomPaypalButton({
                               isDonation,
                               company,
                               total,
                               name,
                               paypalAccount,
                               purchase_units,
                               callback,
                               handleDonationCallback,
                               style,
                               promoCode,
                               upsellType
                            }) {
    const showButtons = useMemo(() => !!PayPalButton,[PayPalButton])
   const [loading, setLoading] = useState(false)
   const [errorMsg, setErrorMsg] = useState('')

   const addPaypalSdk = () => {
      loadScript = true
      setLoading(true)
      const clientID =
         process.env[`REACT_APP_PAYPAL_${paypalAccount}_CLIENT_ID`]
      //process.env[`REACT_APP_PAYPAL_CLIENT_ID`]
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&components=messages,buttons`;
      script.async = true;
      script.onload = () => {
         PayPalButton = window.paypal.Buttons.driver('react', {
            React,
            ReactDOM
         })
         setLoading(false)
         loadScript = false
      }
      script.onerror = () => {
         setErrorMsg("There was an issue while trying to connect with PayPal services, please try again later")
         setLoading(false)
      };
      document.body.appendChild(script);
   }

   useEffect(() => {
    if (!!window && !window.paypal && !loadScript)
         addPaypalSdk();
   }, [])


   const createOrder = (data, actions) => {

      return actions.order.create({
         purchase_units: purchase_units()
      });
   };

   const onApprove = (data, actions) => {
      setLoading(true)
      return actions.order.capture().then((details) => {
         details.purchase_units[0].items.forEach(item => {
            const [transactionType, description] = item.description.split('/')
            item.name = transactionType
            item.description = description
         })
         details.payer.customer_company = company || ''
         details.purchase_units[0].shipping.name.full_name = name || ''
         details.paypal_account = paypalAccount
         details.discount_details = promoCode
         if (!!upsellType) details.fundingType = upsellType
         API.post('enrichHer-mvp', 'checkoutWebhook?payment_method=paypal',
            {body: details})
            .then(() => {
               if (isDonation) {
                  handleDonationCallback(200)
               } else {
                  callback(200)
               }
               setLoading(false)
            })
            .catch(() => {
               setLoading(false)
               setErrorMsg('There was an error processing your payment. Please review your information and try again')

            });
      });
   };

   const onError = (err) => {
      callback(err)
   }

   const onCancel = (data) => {
      callback(data)
   }
   return (<div className="main">
      {loading && <div id="cover-spin"></div>}
      <PayLaterMessage
         layout="text"
         logoType="inline"
         textColor="black"
         amount={total}
      />
      {showButtons && (
         <div>
            <PayPalButton
               style={style}
               createOrder={(data, actions) => createOrder(data, actions)}
               onApprove={(data, actions) => onApprove(data, actions)}
               onError={(data, actions) => onError(data, actions)}
               onCancel={(data, actions) => onCancel(data, actions)}
            />
         </div>
      )}
      <Col>
         <p className={`error-message text-center true`}
            id="error-message-api">
            {errorMsg}
         </p>
      </Col>
   </div>)

}

export default CustomPaypalButton
