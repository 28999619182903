import React from 'react'

const modalWording = {
    title: "What's Next?",
    body: <p>Keep an eye out for the registration email to create your account on <a href="https://enrichher.com/">EnrichHER.com</a>.
    With your own account, you will have a personal dashboard where you can check the status of your application, access funding opportunities such as grants & pitch competitions, and so much more.</p>,
    button: 'OK'
}

export default modalWording
