import React from 'react'
import Checked from '../Assets/checked.svg'
import UnChecked from '../Assets/unchecked.png'
import CheckedArrow from '../Assets/checked-arrow.png'
import UnCheckedArrow from '../Assets/unchecked-arrow.png'


const CustomCheckbox = ({ checked, onChange, type }) => { 
    const checkedIcon = type === 'Product' ? Checked : CheckedArrow
    const unCheckedIcon = type === 'Product' ? UnChecked : UnCheckedArrow


    return(    
        <span style={{marginRight: '5px'}}>
            <label>
                <input type='checkbox' checked={checked} onChange={onChange} style={{display:'none'}}/>
                <span className="upselling-checkbox">
                    <img
                        style={{ width: '20px', height: '20px'}}
                        src={checked ? checkedIcon : unCheckedIcon}   
                        alt='checkbox' 
                    />
                </span>                
            </label> 
        </span>
    )
}


export default CustomCheckbox
