import React from 'react'
export function ReusableInput({ classInput = '', className = '', classLabel = 'null', classContainer = '', label, error = '', ...rest }) {

    return (
        <div className={classContainer}>
            {label && <label className={`label-input ${classLabel}`} htmlFor={rest.id}> {label} </label>}
            <input {...rest} className={`ERH-input ${classInput} ${error ? 'error' : ''}`}
            />
            {error && <p className={`error-input`}>{error}</p>}
        </div>);
}
