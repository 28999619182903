import React, { useState, useEffect } from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ReusableInput } from '../Input'
import { Button } from "../Button"
import DashedHorizontalRule from '../DashedHorizontalRule'
import InterrogateIcon from '../../Assets/Icons/interrogate.svg';
import PromoCodeTooltipImage from '../../Assets/promo-code-tooltip.png';

const PromotionCode = ({ disablePromoCode, setLoader, checkPromoCode, showDiscount }) => {
    const [promoCode, setPromoCode] = useState('')
    const [discountInformation, setDiscountInformation] = useState({ isValid: undefined })

    useEffect(() => {
        !showDiscount && setPromoCode('')
    }, [showDiscount])

    const handleApply = async (e) => {
        e.preventDefault()
        try {
            await setLoader(true)
            const discountDetails = await checkPromoCode(promoCode)
            setDiscountInformation({ ...discountDetails })
        } catch {
            await setLoader(false)
        }
    }

    const discountDetails = discountInformation.isValid === undefined ? null :
        <Col xs={12} className='d-flex flex-wrap flex-grow-1 align-items-center justify-content-between' >
            {discountInformation.isValid === true &&
                <>
                    <h6>Discount</h6>
                    <h6 xs={5} className="text-right"><strong>-${discountInformation.discount.toFixed(2)}</strong></h6>
                </>
            }
        </Col>
    
    const invalidPromocode = <p className="error-input" style={{ margin: '0 auto' }}>{discountInformation.message}</p>


    return (
        <div className='tip-container'>
            <Row style={{ textAlign: "left" }}>
                <Col xs={12} className='d-flex flex-wrap flex-grow-1 align-items-center justify-content-between' style={{ paddingRight: '.8rem' }}>
                    <div className='d-flex'>
                        <h6 id="promotion-code-label">Promotion Code</h6>
                        <OverlayTrigger
                            trigger='click'
                            rootClose={true}
                            placement="auto"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    <div className='d-flex align-items-center promotion-code'>
                                        <img src={PromoCodeTooltipImage} width='140' height='120' />
                                        <p className='content'>All other sections <b>must be completed and saved</b> to enter promo code.</p>
                                    </div>
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/lQsxiH0B1Hw"></iframe>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <img className='mx-2 question-mark-icon' src={InterrogateIcon} alt='' id='promotion_code' />
                        </OverlayTrigger>
                    </div>
                    <ReusableInput
                        label=''
                        placeholder="Promotion code"
                        name="promotionCode"
                        onChange={(e) => setPromoCode(e.target.value.trim())}
                        value={promoCode}
                        classInput={discountInformation?.isValid === false && "error"}
                        disabled={disablePromoCode}
                    />
                    <Button
                        className='my-2'
                        outline
                        round
                        onClick={e => handleApply(e)}
                        disabled={disablePromoCode}
                    >
                        Apply
                    </Button>
                </Col>
                {showDiscount && discountDetails}
                {discountInformation?.isValid === false && invalidPromocode}
                <Col xs={12}>
                    <DashedHorizontalRule size='small'/>
                </Col>
            </Row>
        </div>
    )
}


export default PromotionCode
