import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';

let parseProducts = (products) => {
  let parsedProducts = new Array()
  products.forEach(element => {
    parsedProducts.push({
      'label': element.name,
      'amount': Math.round(element.amount * 100) 
    });
  });
  return parsedProducts
};

export const StripeRequestButton = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [errorMessage, setErrorMessage] = useState()
  const [paymentDone, setPaymentDone] = useState(false)

  useEffect(() => {
    setPaymentRequest(null)
    props.updateProducts()
    if (stripe && props.products) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: Math.round(props.total * 100),
        },
        displayItems: parseProducts(props.products),
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        } else {
          setErrorMessage('There is no wallet available on this browser.')
        }
      });
    }
  }, [stripe, props.total]);


  useEffect(() => {
    if(paymentRequest){
      paymentRequest.on('token', function(ev) {
        if(ev.token.id && !paymentDone) {
          props.handleToken(ev.token.id)
          ev.complete('success')
          props.handlePayment()
          setPaymentDone(true)
        } 
        else {
          ev.complete('false')
          setErrorMessage('Unexpected error, the payment is not completed. Please try with another payment method.')
        }
      })
    }
  }, [paymentRequest]);

  return paymentRequest ? <PaymentRequestButtonElement options={{paymentRequest}} /> 
  : errorMessage ? <p className="error-message text-center true">{errorMessage}</p> 
  : <div id="cover-spin"></div>
}