
import React from "react";
import { Row, Col } from "react-bootstrap";
import { formatAmount } from "../helpers"
import DashedHorizontalRule from "./DashedHorizontalRule"
import PromotionCode from "../Components/PromotionCode/PromotionCode"

function ProductsSummary(props) {
    let { subTotal, products, dontShowProducts } = props


    return (
        <Row style={{ textAlign: 'left' }} className='product-summary'>
            <Col xs={12}>
                {!dontShowProducts ?
                    products ? (
                        products.filter(product => product.name != 'Tip').map((element, index) =>
                            <Row key={index} className='mb-3'>
                                <Col xs={7} >{element.name}</Col>
                                <Col xs={5} className="text-right"> <b> ${formatAmount(element.amount)} </b></Col>
                            </Row>
                        ))
                        :
                        <Row>
                            <Col xs={8}>{ }</Col>
                            <Col xs={4}>{0}</Col>
                        </Row>
                    :
                    <></>
                }
                {!dontShowProducts && <DashedHorizontalRule size='small' />}
                <Row style={{ marginBottom: 15, marginTop: 10 }}>
                    <Col xs={7}>SubTotal</Col>
                    <Col xs={5} className="text-right"><strong>${formatAmount(subTotal != null ? subTotal : 0)}</strong></Col>
                </Row>
                <DashedHorizontalRule size='small' />
            </Col>
        </Row>


    )
}

export default ProductsSummary