export function formatAmount(amount, decimalCount = 2, thousands = ","){
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseFloat(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    var res = i.split(".");   
    if(res.length === 1 || res[1].length < 3) { 
        i = parseFloat(i).toFixed(decimalCount)
    }
    let j = (res[0].length > 3) ? res[0].length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
 }

export function validatePhoneNumber(phone) {
    const phoneRegex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
    return phoneRegex.test(phone);
}
export function validateWebsite(websiteurl) {
    const websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    return websiteRegex.test(websiteurl);
}

export function setStripePk(account){
    let stripePk = '';
    switch(account){
        case 'BC' :
            stripePk = process.env.REACT_APP_STRIPE_BO_KEY
            break;
        case 'LE':
            stripePk = process.env.REACT_APP_STRIPE_LE_KEY
            break;
        case 'ES':
            stripePk = process.env.REACT_APP_STRIPE_ES_KEY
            break;
        default:
            break;
        }

    return stripePk;
    
}
