import React from 'react'
import { Button } from './Button.jsx'

const Modal = ({ title, body, btnText, closeModal }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h4>{title}</h4>
                </div>
                <div className="modal-body">
                    <p>{body}</p>
                </div>
                <div className="modal-footer">
                    <Button onClick={(e) => closeModal()}>
                        {btnText}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Modal
