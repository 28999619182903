import React from "react";
import 'react-rangeslider/lib/index.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Styles/css/bootstrap.min.css';
import "./Styles/index.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Checkout } from "./Layouts/Checkout.jsx";


function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/:token" component={Checkout} />
      </Router>
    </div>
  );
}

export default App;
