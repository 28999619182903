import React from 'react'


const DashedHorizontalRule = ({ size }) => {
    const [scale, x1, x2, stroke] = size === 'big' ? 
        ['5', '0.5', '9000', '3,2'] : ['2', '1', '9000', '1,2']


    return (
        <div style={{ display: 'flex', height: '8px', marginBottom: `${size === 'big' ? `10px` : ``}`}}>
             <svg stroke="#bbbcbf" fill="#bbbcbf" width='8400' >
                <g transform={`scale(${scale}) translate(0, 0.5)`}>
                    <line x1={`${x1}`} x2={`${x2}`} stroke-dasharray={`${stroke}`} stroke-linecap="round" fill="none">
                    </line>
                    <g transform="translate(102.97582960087439)">
                    </g>
                </g>
            </svg>
        </div>
    )
}


export default DashedHorizontalRule
