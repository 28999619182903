import React from 'react'
import CustomCheckbox from '../CustomCheckbox'


const Product = ({ index, productDetails, toggleProductIsSelected }) => {
    const {
        name,
        description,
        currentPrice,
        priceWithDiscount,
        isSelected
    } = productDetails
    const [title, subtitle] = name.split(':')

    
    const handleCheckboxClick = () => toggleProductIsSelected(index, isSelected)


    return (
        <div className='flex upselling-product'>
            <CustomCheckbox
                checked={isSelected}
                onChange={handleCheckboxClick}
                type='Product'
            />
            <div className='upselling-product-body'>
                <p>
                    <span className='blue'>{title}: </span>
                    {subtitle && <span>{subtitle} -</span>}
                    &nbsp;Now ${priceWithDiscount}
                </p>
                <p>
                    <span className='blue'> (Valued at ${currentPrice}) -&nbsp; </span>
                    {description}
                </p>
            </div>
        </div>
    )
}


export default Product
