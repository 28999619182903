
import { API } from "aws-amplify";
import handleAsyncCall from "./Utils/handleAsyncCall";

const axios = require('axios').default;


export const sentStripePayment = (data, callback) => {
  API.post('enrichHer-mvp', 'stripeCheckout', { body: data })
    .then(response => {
      callback(200)
    })
    .catch(error => {
      callback(error)
    });
}

export const createUser = (data, callback) => {
  return axios.post(process.env.REACT_APP_WP_ENDPOINT + '/user_manager/v1/create',
    data).then(function (response) {
      callback(200)
    }).catch(function (error) {
      callback(error.response.data);
    })

}

export const createUserFromCheckout = (data, callback) => {
  return axios.post(process.env.REACT_APP_WP_ENDPOINT + '/user_manager/v1/create_user_from_checkout', data)
    .then(function (response) {
      if (response.status === 200) callback(200)
      if (response.status === 201) callback(201)
    })
    .catch(function (error) {
      callback(400)
    })
}

export const sentDonation = (data, callback) => {
  return axios.post(process.env.REACT_APP_WP_ENDPOINT + '/donation-requests/v1/send_donation',
    data, {
      headers: { 'Content-Type': 'application/json' }
  }).then(function (response) {
    callback(200)
  }).catch(function (error) {
    console.log(error);
    callback(error)
  })
}

export const getUpsellingProducts = async upsellType => {
  const [data, error] = await handleAsyncCall(
    API.post('enrichHer-mvp', 'upsellingProducts', { body: { upsellType } })
  )
  return error ? null : data
}

export const checkPromotionCodeValidity =  async (payload) => {
  try {
    const promoCodeStatus = await API.post('enrichHer-mvp', 'checkoutPromotionCode', { body: payload })
    return promoCodeStatus
  } catch (error) {
    console.log(error)
  }
}

export const getProration =  async (payload) => {
  try {
    const response = await API.post('enrichHer-mvp', 'checkoutGetProration', { body: payload })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const sendErhWithLoveNotification = (payload) => {
  const url = process.env.REACT_APP_ERH_WITH_LOVE_TEXT_MESSAGE_ZAP
  const messageBody =  'Waitlist Message'
  axios.post(url, JSON.stringify({ ...payload, messageBody }))
}

export const paymentSlackNotification = (payload) => {
  const url = process.env.REACT_APP_SLACK_NOTIFICATION_ZAP
  const body =  {
    email: payload.email,
    first_name: payload.first_name,
    last_name: payload.last_name,
    home_address: payload.home_address,
    home_state: payload.home_state,
    home_city: payload.home_city,
    home_zip_code: payload.home_zip_code,
    phone_number: payload.phone_number,
    product_name: payload.products[0].name
  }
  axios.post(url, JSON.stringify({ body }))
}

export const sendDashboardNotification = (payload) => {
  const url = `${process.env.REACT_APP_WP_ENDPOINT}/erh_notifications/v1/handle_trigger`;
  axios.post(url, payload);
}

export const linkMarketingCampaign = (payload) => {
  const url = process.env.REACT_APP_WP_ENDPOINT + '/zoho/v1/link_marketing_campaign';
  const body =  {
    email: payload.email,
    marketing_campaign: payload.marketing_campaign 
  }
  axios.post(url, JSON.stringify(body), {headers: { 'Content-Type': 'application/json' }})
}