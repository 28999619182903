import React, { useEffect, useState } from 'react';
import { PlaidLink as ButtonPlaid } from 'react-plaid-link';
import { API } from 'aws-amplify'

function PlaidLink({ stripeAccount, email, handleToken, handlePayment, setLoader, totalAmount }) {
   const [isLoad, setLoading] = useState(false)
   const [isError, setError] = useState(false)
   const [linkToken, setLinkToken] = useState()
   const [errorMessage, setErrorMessage] = useState()

   useEffect(() => {
      if (email && !isLoad) {
         setLoading(true)
         API.post('enrichHer-mvp', 'plaidLink', {
            body: {
               stripe_account: stripeAccount,
               customer_email: email,
               request_type: 'create_link_token'
            }
         }).then(({ link_token }) => {
            setLinkToken(link_token)
         }).catch(err => {
            setError(true)
            setErrorMessage("There was an issue while trying to connect with Plaid services, please try again later")
            setLoading(false)
         })
      }
      return () => {
         setLinkToken(null)
         setLoading(false)
         setError(false)
         setErrorMessage(null)
      }
   }, [email, stripeAccount, totalAmount])

   const onSuccess = async (token, metadata) => {
      setLoader(true)
      await API.post('enrichHer-mvp', 'plaidLink', {
         body: {
            stripe_account: stripeAccount,
            request_type: "set_access_token",
            public_token: token,
            account_id: metadata.account_id,
            total_amount: totalAmount
         }
      })
         .then(response => {
            if (response.statusCode == 200) {
               handleToken(response.message)
               handlePayment()
            }
            else {
               setError(true)
               setErrorMessage(response.message)
               setLoader(false)
            }
         })
         .catch(error => {
            setError(true)
            setErrorMessage("There was an issue while trying to connect with Plaid services, please try again later")
            setLoader(false)
         });
   }

   const onLoad = async () => {
      setLoading(false)
   }

   return <>
      {isLoad && <div id="cover-spin"></div>}
      {linkToken && <ButtonPlaid
         token={linkToken}
         onSuccess={onSuccess}
         onLoad={onLoad}
         className="ERH-btn round my-2"
         style={{ width: '100%' }}>
         Plaid
      </ButtonPlaid>}
      {isError &&
         <p className="error-message text-center true">
            {errorMessage}
         </p>}
   </>


}

export default PlaidLink
