import React from 'react'
import CustomCheckbox from '../CustomCheckbox'

const BonusProduct = ({ bonusIndex, bonusDetails, toggleBonusIsSelected }) => {
    const {
        name,
        description,
        currentPrice,
        isSelected
    } = bonusDetails


    const handleCheckboxClick = () => toggleBonusIsSelected(bonusDetails, bonusIndex, isSelected)


    return (
        <div className='upselling-product-body upselling-bonus'>
            <div className='flex'>
                <CustomCheckbox
                    checked={isSelected}
                    onChange={handleCheckboxClick}
                    type='Bonus'
                />
                <div className='upselling-product-body'>
                    <p>
                        <span className='blue'>BONUS:</span>
                        &nbsp;{name}&nbsp;
                    </p>
                    <p>
                        <span className='blue'> (Valued at ${currentPrice}) -&nbsp; </span>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}


export default BonusProduct
