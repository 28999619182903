export function getFormattedUpsellingProducts(products) {
    const formattedProducts = []


    products.forEach(product => {
        const { 
            name, 
            description, 
            currentPrice, 
            priceWithDiscount, 
            transactionType, 
            subscriptionAlias, 
            stripeProductId,
            stripePriceId 
        } = product
        
        const formattedProduct = {
            name,
            amount: priceWithDiscount ?? currentPrice,
            transaction_type: transactionType,
            description
        }
        if (subscriptionAlias) formattedProduct.subscription = subscriptionAlias
        if (stripeProductId) formattedProduct.stripeProductId = stripeProductId
        if (stripePriceId) formattedProduct.stripePriceId = stripePriceId
        
        formattedProducts.push(formattedProduct)
    })


    return formattedProducts
}


export function removeSubscriptionsFromUpsellingProducts (upsellingProducts) {
    return upsellingProducts.filter(product => !product.subscriptionAlias) 
}

export function getProductType (data) {
    const hasSubscription = !!data.products.filter(product => product.subscription)?.length
    return hasSubscription ? 'subscriptions' : data.productType
}

export function updateProductsInformation(products, upsellType) {
    return products.map(product => {

        if (product.transaction_type === 'product') product.description = product.name
        if (product.transaction_type === 'Application Fee' && !!upsellType) product.fundingType = upsellType 

        return product
    })
}