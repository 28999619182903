import React from 'react';
function PayPalPayLaterMessage({
                               layout,
                               logoType,
                               textColor,
                               amount,
                            }) {

   return (
        <div
            className = "pay-later-message"
            data-pp-message
            data-pp-style-layout={layout}
            data-pp-style-logo-type={logoType}
            data-pp-style-text-color={textColor}
            data-pp-amount={amount}>
        </div>
    )

}

export default PayPalPayLaterMessage
