import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import { CardTitle } from 'reactstrap';
import TipSection from './TipSection.jsx'
import ProductsSummary from './ProductsSummary'
import CompletePurchaseSection from './CompletePurchaseSection'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import UpsellingProducts from './UpsellingProducts/UpsellingProducts.jsx'
import PurchaseTotal from './PurchaseTotal.jsx';
import PromotionCode from "./PromotionCode/PromotionCode.jsx";


function PaymentSummary(props) {
   const [showTipSection, setShowTipSection] = useState(window.innerWidth >= 980);
   const products = props.products
   const subTotal = props.subTotal ? props.subTotal : 0

   useEffect(() => {
      window.addEventListener('resize', updateSize);
   })


   function updateSize() {
      let showSection = (window.innerWidth >= 980);
      if (showSection !== showTipSection) {
         setShowTipSection(showSection)
      }
   }

   return (
      <Card className="payment-summary">

         <CardTitle className="mb-4" id="payment-summary-title"> {props.title}</CardTitle>
         <hr style={{ marginTop: '5px' }} />
         <ProductsSummary 
            products={products} 
            subTotal={props.subTotal}
            hideHr={!showTipSection}
         />
         {showTipSection &&
            <div>
               {props.type === 'products' &&
                  <TipSection subTotal={props.subTotal} calculateTip={props.calculateTip} />
               }
               {props.showPromoCodeInput && 
                  <PromotionCode 
                     setLoader={props.setLoader}
                     checkPromoCode={props.checkPromoCode} 
                     disablePromoCode={props.disableButton}   
                     showDiscount={!!props.promoCode?.discount}
                  />
               }
               <PurchaseTotal total={props.total} />
               <UpsellingProducts
                  purchaseEnabled={!props.disableButton}
                  upsellingProducts={props.upsellingProducts}
                  upsellingBonuses={props.upsellingBonuses}
                  toggleProductIsSelected={props.toggleProductIsSelected}
                  toggleBonusIsSelected={props.toggleBonusIsSelected}
               />
               <CompletePurchaseSection
                  email={props.email} value={props}
                  setLoader={props.setLoader}
                  stripeAccount={props.stripeAccount}
                  stripePromise={props.stripePromise}
                  handleToken={props.handleToken}
                  name={props.name} email={props.email} isDonation={props.isDonation}
                  disableButton={props.disableButton} updateProducts={props.updateProducts}
                  paypal={props.paypal} ach={props.ach} wallet={props.wallet} subTotal={props.subTotal} 
                  total={props.total} handlePayment={props.handlePayment} callback={props.callback} products={products}
                  company={props.company} handleDonationCallback={props.handleDonationCallback}
                  promoCode={props.promoCode} upsellType={props.upsellType}
               />
            </div>
         }
         
      </Card>
   )
}

export default PaymentSummary