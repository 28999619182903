import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { CardTitle } from 'reactstrap'
import DashedHorizontalRule from '../DashedHorizontalRule'
import BonusProduct from './BonusProduct'
import Product from './Product'


const UpsellingProducts = (props) => {
    const {
        purchaseEnabled,
        upsellingProducts,
        upsellingBonuses,
        toggleProductIsSelected,
        toggleBonusIsSelected
    } = props

    return (purchaseEnabled && upsellingProducts?.length) ?
        <>
            <Row>
                <Col xs={12}>
                    <DashedHorizontalRule size='big' />
                    <CardTitle className="mb-4" id="upselling-products-title">CREATE A BUNDLE</CardTitle>
                </Col>
            </Row>
            <Row className='product-summary'>
                <Col xs={12}>
                    {upsellingProducts.map((product, index) =>
                        <Product
                            key={product.id}
                            index={index}
                            productDetails={product}
                            toggleProductIsSelected={toggleProductIsSelected}
                        />
                    )}
                    <DashedHorizontalRule size='big' />
                </Col>

                {upsellingBonuses.length > 0 ?? 
                    <Col xs={12}>
                        <CardTitle className="mb-4" id="upselling-products-title">BONUS</CardTitle>
                        {upsellingBonuses.map((bonus, index) =>
                            <BonusProduct
                                key={bonus.id}
                                bonusIndex={index}
                                bonusDetails={bonus}
                                toggleBonusIsSelected={toggleBonusIsSelected}
                            />
                        )}
                    </Col>
                }
            </Row>
        </>
        : null
}


export default UpsellingProducts
