import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ReusableInput } from "./Input";

const options = {
  style: {
    base: {
      width: '100%',
      fontSize: "1em",
      padding: '.75rem',
      fontFamily: '"Lyon Display Web"',
      borderColor: 'transparent',
      borderRadius: '10px',
      color: "#000000",
      backgroundColor: "transparent",
      letterSpacing: "0.05em",
      lineHeight: "3rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#db6382",
    },
  },
};

export const StripeSection = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (!name || !name.trim()) {
      setErrorMessage("Please enter a name");
      return setError(true);
    }
    const card = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(card, {
      type: "card",
      name: name.trim(),
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("Information saved");

      props.handleToken(token.id);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-form" id="payment-form">
      <ReusableInput
        classInput={` border 
        ${ error
            ? "error"
            : ""}
            `
        }
        id='name_on_card'
        classContainer='my-2'
        name="name_on_card"
        placeholder="Name on card"
        label='Name on card'
        onChange={(event) => {
          setName(event.target.value);
        }}
        value={name}
      />
      <CardNumberElement options={options} className="stripe-inputs" />
      <CardExpiryElement
        options={options}
        className="stripe-inputs half-size medium-gap"
      />
      <CardCvcElement options={options} className="stripe-inputs half-size" />
      {errorMessage && <p className="pink-text">{errorMessage}</p>}
    </form>
  );
};
