/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "./Button";
import { ReusableInput } from "./Input";
import { formatAmount } from "../helpers";
import DashedHorizontalRule from "./DashedHorizontalRule";

function TipSection(props) {
  const subTotal = !isNaN(+props.subTotal) ? +props.subTotal : 0;
  const [tipAmount, setTipAmount] = useState(!isNaN(+props.tip) ? +props.tip : 0);
  React.useEffect(() => {
    validateTip(+(subTotal * 0.15).toFixed(2));
  }, []);
  const validateTip = useCallback((value) => {
    if (value === '')
      return setTipAmount(value)
    if (value > 0) {
      if (+value > 999999 - subTotal) {
        setTipAmount(+(999999 - subTotal).toFixed(2));
      } else {
        setTipAmount(+(+value).toFixed(2));
      }
    } else {
      setTipAmount(0);
    }
  }, []);

  const calculate = () => {
    tipAmount
      ? props.calculateTip(+tipAmount)
      : props.calculateTip(0);
  };

  return (
    <div className='tip-container'>
      <Row style={{ textAlign: "left" }}>
        <Col xs={12} className='d-flex flex-wrap flex-grow-1 align-items-center justify-content-between'>
          <h6>Tip for our awesomeness</h6>
            <ReusableInput
              classContainer='mx-4 my-2'
              placeholder="Enter custom amount"
              name="Enter custom amount"
              onChange={(e) => validateTip(e.target.value)}
              value={tipAmount}
              type="number"
            />
          

            <Button className='my-2' outline round onClick={calculate}>
              Apply
              </Button>
          
        </Col>
      </Row>
      <DashedHorizontalRule size='small'/>
    </div>
  );
}

export default TipSection;
