import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from './Button'
import PayPalCustomButton from './PayPalButton'
import PlaidButton from './PlaidLink'
import { StripeRequestButton } from './StripeRequestButton'
import { Elements } from "@stripe/react-stripe-js";
import { formatAmount } from "../helpers"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


function CompletePurchaseSection(props) {
   const total = React.useMemo(() => !isNaN(+props.total) ? +(+props.total).toFixed(2) : 0, [props.total])
   let disableButton = props.disableButton
   const handleLoader = () => {
      props.handlePayment()
   }

   const createPurchaseUnits = () => {
      const maximumDescriptionLength = 126
      props.updateProducts()
      let purchaseUnits
      let purchaseDescription = '-'

      const items = props.products.map((product) => {
         const productDescription = product.transaction_type === 'product' ? product.name : product.description
         
         if (props.promoCode?.productId && product.stripeProductId === props.promoCode.productId) {
            purchaseDescription = `${product.name} with promotion code: ${props.promoCode.promoCode}`.substring(0,maximumDescriptionLength) 
         }

         return {
            name: product.name,
            description: product.transaction_type + '/' + productDescription,
            unit_amount: {
               currency_code: "USD",
               value: +(+product.amount).toFixed(2)
            },
            quantity: "1"
         }
      })

      const totalWithoutDiscount = props.products.reduce((accumulator, current) => 
         +(accumulator + +current.amount).toFixed(2), 0)

      const discount = +(totalWithoutDiscount - total).toFixed(2)
         
      purchaseUnits = [
         {
            amount: {
               value: total,
               breakdown: {
                  item_total: {
                     currency_code: 'USD',
                     value: totalWithoutDiscount,
                  },
                  discount: {
                     currency_code: 'USD',
                     value: discount
                  }
               }
            },
            items: items,
            custom_id: props.email,
            description: purchaseDescription
         }
      ]
      
      return purchaseUnits
   }

   return (
      <Row className='complete-purchase' style={{ textAlign: 'left' }}>
         <Col xs={12} className='d-flex justify-content-between flex-wrap flex-grow-1 my-1'>
            <Col xs={12}>
               {props.paypal && !disableButton ?
                  <PayPalCustomButton name={props.name} company={props.company} total={total}
                     style={{ layout: 'horizontal', shape: 'pill' }} callback={props.callback}
                     email={props.email} purchase_units={createPurchaseUnits}
                     handleDonationCallback={props.handleDonationCallback}
                     paypalAccount={props.stripeAccount} isDonation={props.isDonation} 
                     promoCode={props.promoCode} upsellType={props.upsellType}/>
                  : props.ach && !disableButton ?
                     <PlaidButton
                        handlePayment={props.handlePayment}
                        handleToken={props.handleToken}
                        setLoader={props.setLoader}
                        stripeAccount={props.stripeAccount} email={props.email}
                        totalAmount={total}
                        callback={props.isDonation ? props.handleDonationCallback : props.callback}
                     />    
                  : props.wallet && !disableButton ?
                  <Elements stripe={props.stripePromise}>
                     <StripeRequestButton 
                        handlePayment={props.handlePayment}
                        handleToken={props.handleToken}
                        total={total} 
                        products={props.products}
                        updateProducts={props.updateProducts}
                        setLoader={props.setLoader}
                     />
                  </Elements>
                  :
                  <Button type="submit"
                     className='my-3'
                     style={{ width: "100%" }}
                     onClick={handleLoader} disabled={disableButton}>Complete Purchase</Button>
               }
               <p className="text-center mt-1" id="secure">Secure 🔐</p>
            </Col>
         </Col>
      </Row>
   )
}

export default CompletePurchaseSection
