/* eslint-disable */
import React, { Component } from "react";
import { Accordion, Card, Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Header } from "../Components/Header.jsx";
import Footer from "./Footer.jsx";
import { Button } from "../Components/Button.jsx";
import { ReusableInput } from "../Components/Input.jsx";
import { StripeSection } from "../Components/StripeSection";
import { setStripePk, validatePhoneNumber } from "../helpers";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { checkPromotionCodeValidity, 
        createUser, 
        createUserFromCheckout, 
        sentDonation, 
        sentStripePayment,
        getProration, 
        getUpsellingProducts, 
        sendErhWithLoveNotification,
        paymentSlackNotification, 
        sendDashboardNotification,
        linkMarketingCampaign, 
} from "../services.js";
import TipSection from "../Components/TipSection";
import CompletePurchaseSection from "../Components/CompletePurchaseSection";
import ProductsSummary from "../Components/ProductsSummary";
import jwt from "jsonwebtoken";
import paypalLogo from "../Assets/paypal.png";
import PaymentSummary from "../Components/PaymentSummary.jsx";
import RowIcon from "../Assets/angle-down-solid.svg";
import creditCardLogos from "../Assets/creditCardLogos.png";
import walletLogos from "../Assets/wallets-logo.png"
import InterrogateIcon from '../Assets/Icons/interrogate.svg';
import BillingTooltipImage from '../Assets/billing-address-tooltip.png';
import PaymentTooltipImage from '../Assets/payment-tooltip.png';
import { ERHRadioBtn } from "../Components/RadioButton.jsx";
import Modal from "../Components/Modal.jsx";
import modalWording from "../Utils/checkoutModalWording";
import { getFormattedUpsellingProducts, removeSubscriptionsFromUpsellingProducts, getProductType, updateProductsInformation } from "../Utils/upsellingProductsHelpers.js";
import UpsellingProducts from '../Components/UpsellingProducts/UpsellingProducts'
import PurchaseTotal from '../Components/PurchaseTotal';
import PromotionCode from "../Components/PromotionCode/PromotionCode.jsx";

let stripePromise = loadStripe("pk_test");

export class Checkout extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            data: {
                email: "",
                disableEmail: "",
                first_name: "",
                last_name: "",
                phone_number: "",
                home_address: "",
                home_address_2: "",
                home_city: "",
                home_state: "",
                home_zip_code: "",
                password: "",
                repeatPassword: "",
                paymentType: "",
                productType: "",
                subTotal: 0,
                tip: 0,
                tipAmount: "default",
                total: 0,
                token: "",
                enrichHerAccount: "",
                products: [],
                upsellingProducts: [],
                upsellingBonuses: [],
                activeSubscription: "",
                company: "",
                donationMessage: "",
                redirectOnSuccess: process.env.REACT_APP_BASE_WP_URL + "/dashboard/",
                dashboard: "Company",
                marketing_campaign: {
                    utm_campaign: "",
                    utm_source: "",
                    utm_medium: ""
                }
            },
            validations: {
                validInput_first_name: true,
                validInput_email: true,
                validInput_last_name: true,
                validInput_phone_number: true,
                validInput_home_address: true,
                validInput_home_city: true,
                validInput_home_state: true,
                validInput_home_zip_code: true,
                validInput_repeatPassword: true,
                validInput_password: true,
            },
            isDonation: false,
            userCreated: false,
            showSubmitError: false,
            errormsg: "",
            hideSection: false,
            showPhoneError: false,
            showSaveMessage: false,
            saveMssg: "",
            showSubmitUserError: false,
            submitUserErrorMsg: "",
            loader: false,
            showPayPalBtn: false,
            showACHBtn: false,
            showModal: false,
            upsellType: null,
            allUpsellingProducts: [],
            allUpsellingBonuses: [],
            promoCode: null,
            prorationDate: null,
            showPromoCodeInput: false,
            erhWithLoveNotification: null 
        };
        this.myRef = React.createRef();
    }


    componentDidMount = async () => {
        const jwtToken = this.props.match.params.token;
        let new_state = Object.assign({}, this.state);
        let decoded;
        try {
            decoded = jwt.verify(
                jwtToken.toString(),
                Buffer.from(process.env.REACT_APP_JWT_SECRET, "base64")
            );
            const willUpdateSubscription = decoded.product_type === "subscriptions" && !!decoded.active_subscription;
            if (willUpdateSubscription) {
                const payload = {
                    stripeAccount: decoded.stripe_account,
                    activeSubscription: decoded.active_subscription,
                    newSubscription: decoded.products[0]
                };
                const prorationDetails = await getProration(payload);
                if (prorationDetails?.proration) {
                    decoded.products[0].amount = prorationDetails.proration > 0 ? prorationDetails.proration : 0;
                    new_state.prorationDate = prorationDetails.prorationDate;
                }
            }
            new_state.isDonation = decoded.products[0].transaction_type === "Donation";
            const isLoan = decoded.products[0].transaction_type === "Loan";
            const positiveBalance = decoded.products?.[0]?.amount !== 0;
            new_state.showPromoCodeInput = !new_state.isDonation && !isLoan && positiveBalance;

            new_state.data.products = decoded.products;
            new_state.data.email = decoded.email ? decoded.email : "";
            new_state.data.email != ""
                ? (new_state.data.disableEmail = true)
                : (new_state.data.disableEmail = "");
            new_state.data.company = decoded.company ? decoded.company : "";
            new_state.data.activeSubscription = decoded.active_subscription;
            new_state.data.productType = decoded.product_type;
            new_state.data.donationMessage = decoded.message ? decoded.message : "";
            new_state.data.enrichHerAccount = decoded.stripe_account;
            new_state.data.productType === "subscriptions"
                ? (new_state.data.paymentType = "credit_card")
                : (new_state.data.paymentType = "");
            new_state.data.redirectOnSuccess = decoded.redirectOnSuccess
                ? decoded.redirectOnSuccess
                : new_state.data.redirectOnSuccess;
            new_state.data.campaignId = decoded.campaignId ? decoded.campaignId : null;
            new_state.data.dashboard = decoded.dashboard ?? "Company";
            new_state.upsellType = decoded?.upsellType ?? null;
            new_state.erhWithLoveNotification = decoded.firstName ? 
                { firstName: decoded.firstName, phoneNumber: decoded.phoneNumber }
                :
                null 

            stripePromise = loadStripe(setStripePk(decoded.stripe_account));

            //if it comes as subscription charge the fee as a product
            if (decoded.product_type === "subscriptions" && new_state.data.products[0].amount > 0) {
                let productsAndFee = new_state.data.products;
                let productsAmount = productsAndFee.reduce((total, current) => total + (+current.amount), 0);
                const fee = {
                    subscription: "",
                    amount: this.getNewFee(productsAmount, 0),
                    name: "Credit Card Fee",
                    transaction_type: "fee",
                    description: "Credit Card Fee",
                };
                productsAndFee.push(fee);
                new_state.data.products = productsAndFee;
            }

            if (decoded.marketing_campaign) new_state.data.marketing_campaign = decoded.marketing_campaign;
        } catch (err) {
            window.location.href = process.env.REACT_APP_BASE_WP_URL + "/dashboard/";
        }

        this.setState(new_state, this.getSubTotal(new_state.data.products));

        window.scroll({
            top: 0,
            left: 0,
            behavior: "auto",
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const upsellTypeWasSetted = prevState.upsellType !== this.state.upsellType
        if (upsellTypeWasSetted) {
            const showUpsellingSection = this.state.upsellType === 'Grant'
                || this.state.upsellType === 'All EnrichHER Funding Options'
                || this.state.upsellType === 'EnrichHER with Love'

            showUpsellingSection && this.setUpsellingProducts(this.state.upsellType)
        }
    }


    scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);

    setUpsellingProducts = async (upsellType) => {
        const data = await getUpsellingProducts(upsellType)
        if (data) {
            const { products, bonuses } = data
            const newState = { ...this.state }

            newState.allUpsellingProducts = [...products]
            newState.allUpsellingBonuses = [...bonuses]
            newState.data.upsellingProducts = [...products]
            newState.data.upsellingBonuses = [...bonuses]

            this.setState(newState)
        }
    }

    submitCreateUser = (event) => {
        event.preventDefault();
        let new_state = Object.assign({}, this.state);
        if (this.state.data.email === "") {
            new_state.showSubmitUserError = true;
            new_state.submitUserErrorMsg =
                "Email field cannot be empty. Please Complete all the required fields";
            new_state.validations.validInput_email = false;
        } else if (this.state.data.first_name === "" || this.state.data.last_name === "") {
            new_state.showSubmitUserError = true;
            new_state.submitUserErrorMsg = "Please complete your first and last name";
            new_state.validations.validInput_last_name = false;
            new_state.validations.validInput_first_name = false;
        } else if (this.state.data.password === "") {
            new_state.validations.validInput_password = false;
            new_state.showSubmitUserError = true;
            new_state.submitUserErrorMsg = "Password field cannot be empty";
        } else if (this.state.data.password !== this.state.data.repeatPassword) {
            new_state.validations.validInput_password = false;
            new_state.validations.validInput_repeatPassword = false;
            new_state.showSubmitUserError = true;
            new_state.submitUserErrorMsg = "Passwords should match";
        } else {
            new_state.validations.validInput_password = true;
            new_state.validations.validInput_email = true;
            new_state.validations.validInput_repeatPassword = true;
            new_state.showSubmitUserError = false;
        }

        if (!new_state.showSubmitUserError) {
            let data = {
                email: this.state.data.email,
                password: this.state.data.password,
                name: this.state.data.first_name + " " + this.state.data.last_name,
            };
            let payload = jwt.sign(data, process.env.REACT_APP_JWT_SECRET);
            createUser(payload, this.callbackCreateUser);
        }
        this.setState(new_state);
    };

    handleInputChange = (event) => {
        let statusCopy = Object.assign({}, this.state);
        statusCopy.data[event.target.name] = event.target.value;
        if (event.target.name === "paymentType") {
            let products = statusCopy.data.products;
            let found = products.findIndex(
                (product) => product.transaction_type === "fee"
            );
            found !== -1 ? products.splice(found, 1) : null;
            statusCopy.data.subTotal = +products[0].amount
            const fee = {
                subscription: "",
                transaction_type: "fee",
                amount: +(((statusCopy.data.subTotal + statusCopy.data.tipAmount) * 0.04).toFixed(2))
            }
            if (event.target.value === "credit_card" || event.target.value === "wallet") {
                statusCopy.showACHBtn = false;
                statusCopy.showPayPalBtn = false;
                fee["name"] = "Credit Card Fee";
                fee["description"] = "Credit Card Fee";
                statusCopy.data.upsellingProducts = [...statusCopy.allUpsellingProducts]
            } else {
                statusCopy.data.upsellingProducts = removeSubscriptionsFromUpsellingProducts([...statusCopy.allUpsellingProducts])
            }
            if (event.target.value === "ach") {
                fee["name"] = "ACH Fee";
                fee["description"] = "ACH Fee";
            }
            if (event.target.value === "payPal") {
                fee["name"] = "PayPal Fee";
                fee["description"] = "Paypal Fee";
            }
            products.push(fee);

            statusCopy.data.upsellingProducts = statusCopy.data.upsellingProducts.map(product => {
                product.isSelected = false
                return product
            })
            statusCopy.data.upsellingBonuses = statusCopy.data.upsellingBonuses.map(bonus => {
                bonus.isSelected = false
                return bonus
            })
            products = products.filter(product => product.transaction_type !== 'product')
            products.forEach(product => delete product.amountWithDiscount)
            statusCopy.data.products = products;
            statusCopy.promoCode = null
            this.getSubTotal(products);
        }
        this.setState(statusCopy);
    };

    showButton = (event) => {
        let showBtn = this.state.data.paymentType === "payPal"
        let showBtnACH = this.state.data.paymentType === "ach"
        this.setState({ showPayPalBtn: showBtn, showACHBtn: showBtnACH });
    };

    saveAndContinue = (event) => {
        const regexEmail = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        let requiredFields = [
            "email",
            "first_name",
            "last_name",
            "phone_number",
            "home_address",
            "home_city",
            "home_state",
            "home_zip_code",
        ];
        let statusCopy = Object.assign({}, this.state);
        let isValid = true;
        Object.keys(this.state.data).forEach(function (item) {
            if (
                (statusCopy.data[item.toString()] === "" ||
                    statusCopy.data[item.toString()] === "default" ||
                    statusCopy.data[item.toString()] === "0") &&
                requiredFields.includes(item)
            ) {
                statusCopy.validations["validInput_" + item] = false;
                isValid = false;
            } else if (item.toString() === "email") {
                if (!statusCopy.data[item.toString()].match(regexEmail)) {
                    isValid = false;
                    statusCopy.validations["validInput_" + item] = false;
                }
            } else {
                statusCopy.validations["validInput_" + item] = true;
            }
        });
        if (isValid) {
            statusCopy.showSaveMessage = true;
            statusCopy.saveMssg = "Information Saved";
            this.scrollToMyRef();
        } else {
            statusCopy.showSaveMessage = true;
            statusCopy.saveMssg = "Please complete all the required fields";
        }
        this.setState(statusCopy);
    };

    getSubTotal = (products) => {
        const statusCopy = { ...this.state }
        statusCopy.data.subTotal = +(products
            .filter((product) => product.name != "Tip")
            .reduce((accumulator, product) => accumulator + (product.amountWithDiscount ?? +product.amount), 0)
            .toFixed(2))
        statusCopy.data.tipAmount = statusCopy.data.productType === "subscriptions"
            ? 0
            : statusCopy.data.tipAmount != "default"
                ? statusCopy.data.tipAmount
                : +parseFloat(
                    statusCopy.data.subTotal + statusCopy.data.subTotal * 0.15 > 999999
                        ? 999999 - statusCopy.data.subTotal >= 0
                            ? 999999 - statusCopy.data.subTotal
                            : 0
                        : statusCopy.data.subTotal * 0.15
                ).toFixed(2);
        statusCopy.data.total = statusCopy.data.subTotal + statusCopy.data.tipAmount;
        this.setState(statusCopy);
    };
    getNewFee = (amount, tip) => {
        let fee = (amount + tip) * 0.04
        return +(fee.toFixed(2))
    }
    CalculateTotal = (tipAmount = 0) => {
        let statusCopy = Object.assign({}, this.state);
        let products = statusCopy.data.products;
        let productsAmount = products
            .filter(product => product.transaction_type !== "fee" && product.transaction_type !== "Tip")
            .reduce((total, current) => total + (current.amountWithDiscount ?? +current.amount), 0);
        let newFee = statusCopy.data.paymentType ? this.getNewFee(productsAmount, +tipAmount) : 0
        //Paypal Fee needs to be diffrent than $0 to complete a purchase
        if (statusCopy.data.paymentType === 'payPal' && newFee === 0) newFee = 0.01

        let found = products.findIndex(
            (product) => product.transaction_type === "fee"
        );
        if (found !== -1) {
            products[found].amount = newFee;
            products[found].amountWithDiscount = newFee;
            statusCopy.data.products = products;
        }
        statusCopy.data.subTotal = productsAmount + newFee;
        statusCopy.data.total = productsAmount + (+tipAmount) + newFee;
        statusCopy.data.tipAmount = +tipAmount;
        if (tipAmount === 0) {
            let found = products.findIndex((product) => product.name === "Tip");
            found !== -1 && products.splice(found, 1);
            statusCopy.data.products = products;
        }
        this.setState(statusCopy);
    };

    handleValidation = (event) => {
        const regexEmail = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        let StateUpdated = Object.assign({}, this.state);
        let inputvalue = event.target.value;
        let inputName = event.target.name;
        if (inputName === "phone_number") {
            if (validatePhoneNumber(inputvalue)) {
                StateUpdated.validations["validInput_" + inputName] = true;
                StateUpdated.showPhoneError = false;
            } else {
                StateUpdated.validations["validInput_" + inputName] = false;
                StateUpdated.showPhoneError = true;
            }
        } else if (inputName === "email") {
            if (!inputvalue.match(regexEmail)) {
                StateUpdated.validations["validInput_" + inputName] = false;
            } else {
                StateUpdated.validations["validInput_" + inputName] = true;
            }
        } else {
            if (inputvalue === "") {
                StateUpdated.validations["validInput_" + inputName] = false;
            } else {
                StateUpdated.validations["validInput_" + inputName] = true;
            }
        }
        this.setState(StateUpdated);
    };

    setToken = (tokenP) => {
        let statusCopy = Object.assign({}, this.state);
        statusCopy.data.token = tokenP;
        this.setState(statusCopy);
    };

    updateProducts = () => {

        let StateCopy = Object.assign({}, this.state);

        let products = StateCopy.data.products;
        if (
            StateCopy.data.productType === "products" &&
            StateCopy.data.tipAmount !== 0
        ) {
            const index = products.findIndex((product) => product.name === "Tip")
            const tip = {
                amount: this.state.data.tipAmount,
                description: "Tip",
                name: "Tip",
                transaction_type: "Tip",
            };
            index !== -1 ? products[index] = tip : products.push(tip);
        } else if (StateCopy.data.tipAmount === 0) {
            let found = products.findIndex((product) => product.name === "Tip");
            found !== -1 ? products.splice(found, 1) : null;
            StateCopy.data.products = products;
        }
    };

    createWordPressUser = async () => {
        const { email, first_name, last_name, dashboard, company } = this.state.data
        const userData = {
            email,
            name: first_name + ' ' + last_name,
            dashboard,
            company
        }
        let payload = jwt.sign(userData, process.env.REACT_APP_JWT_SECRET)
        await createUserFromCheckout(payload, this.callbackCreateUserFromCheckout)
    }

    handlePayment = () => {
        this.saveAndContinue();
        this.updateProducts();
        let StateCopy = Object.assign({}, this.state);
        if (StateCopy.data.paymentType === "credit_card" || StateCopy.data.paymentType === "ach" || StateCopy.data.paymentType === "wallet") {
            const products = updateProductsInformation(StateCopy.data.products, StateCopy.upsellType)
            let payload = {
                source_token: StateCopy.data.token,
                customer: {
                    email: StateCopy.data.email,
                    name: StateCopy.data.first_name,
                    lastname: StateCopy.data.last_name,
                    phone: StateCopy.data.phone_number,
                    company: StateCopy.data.company ? StateCopy.data.company : "",
                    active_subscription: StateCopy.data.activeSubscription,
                    address: {
                        country: "US",
                        state: StateCopy.data.home_state,
                        city: StateCopy.data.home_city,
                        line1: StateCopy.data.home_address,
                        line2: StateCopy.data.home_address_2,
                        postal_code: StateCopy.data.home_zip_code,
                    },
                },
                stripe_account: StateCopy.data.enrichHerAccount,
                product_type: getProductType(StateCopy.data),
                items: products,
                promoCode: StateCopy.promoCode
            };
            StateCopy.loader = true;
            if (
                StateCopy.data.products.findIndex(
                    (product) => product.name === "Donation"
                ) !== -1
            ) {
                sentStripePayment(payload, this.handleDonationCallback);
            } else {
                sentStripePayment(payload, this.handleCallback);
            }
        } else if (StateCopy.data.paymentType === "") {
            StateCopy.showSubmitError = true;
            StateCopy.errormsg =
                "Please select a payment method and save it to continue";
        }

        this.setState(StateCopy);
    };

    handleCallback = (response) => {
        let StateCopy = Object.assign({}, this.state);
        if (response === 200 || response.status === "COMPLETED") {
            paymentSlackNotification(StateCopy.data)
            linkMarketingCampaign(StateCopy.data)

            if (StateCopy.erhWithLoveNotification) {
                sendErhWithLoveNotification(StateCopy.erhWithLoveNotification)
            }
            if (StateCopy.data.products.some(product => product.transaction_type === 'Application Fee')) {
                const payload = { 
                    contact_id: StateCopy.data.email, 
                    type: 'NOTIFICATE',
                    notification_id: 'YOUR_LOAN_APPLICATION',
                }
                sendDashboardNotification(payload)
            } 

            if (StateCopy.userCreated) {
                StateCopy.loader = false;
                StateCopy.showModal = true;
            } else {
                window.location.href = this.state.data.redirectOnSuccess;
            }
        } else {
            StateCopy.loader = false;
            StateCopy.showSubmitError = true;
            StateCopy.errormsg =
                "There was an error processing your payment. Please review your information and try again";
        }
        StateCopy.loader = false;
        this.setState(StateCopy);
    };

    handleDonationCallback = (response) => {
        let StateCopy = Object.assign({}, this.state);
        if (response === 200 || response.status === "COMPLETED") {
            let donationPayload = {
                message: StateCopy.data.donationMessage,
                amount: StateCopy.data.products[0].amount,
                email: StateCopy.data.email,
                name: StateCopy.data.first_name,
                campaignId: StateCopy.data.campaignId,
            };
            let data = {
                payload: jwt.sign(donationPayload, process.env.REACT_APP_JWT_SECRET),
            };
            sentDonation(data, this.handleCallback);
        } else {
            StateCopy.loader = false;
            StateCopy.showSubmitError = true;
            StateCopy.errormsg =
                "There was an error processing your payment. Please review your information and try again";
            this.setState(StateCopy);
        }
    };

    callbackCreateUser = (response) => {
        let StateCopy = Object.assign({}, this.state);
        if (response === 200 || response.status === "COMPLETED") {
            StateCopy.showSubmitUserError = true;
            StateCopy.submitUserErrorMsg = "User was created succesfully.";
            StateCopy.userCreated = true;
        } else {
            StateCopy.showSubmitUserError = true;
            StateCopy.submitUserErrorMsg = response;
        }
        this.setState(StateCopy);
    };

    callbackCreateUserFromCheckout = (response) => {
        const goodResponse = { ...this.state, loader: false, showSubmitUserError: false, submitUserErrorMsg: '' }
        if (response === 201) {
            this.setState({ ...goodResponse, userCreated: true })
        } else if (response === 200) {
            this.setState({ ...goodResponse })
        } else {
            this.setState({
                ...this.state,
                loader: false,
                showSubmitUserError: true,
                submitUserErrorMsg: 'Something went wrong. Please try again.'
            })
        }
    };

    closeModal = () => window.location.href = this.state.data.redirectOnSuccess

    toggleProductIsSelected = (index, isSelected) => {
        const newState = { ...this.state }

        // Toggles product selection
        newState.data.upsellingProducts[index].isSelected = !isSelected
        // Reflect product selection on bonus products
        const productIsSelected = !isSelected ? true : false
        const modifiedBonuses = []
        if (productIsSelected) {
            newState.data.upsellingBonuses.forEach(bonus => {
                if (bonus.productId.includes(newState.data.upsellingProducts[index].id)) {
                    bonus.isSelected = true
                    modifiedBonuses.push(bonus)
                }
            })
        } else {
            newState.data.upsellingProducts[index].bonusProducts.forEach(bonus => {
                const otherSelectedProductHasSameBonus = !!newState.data.upsellingProducts.filter(product =>
                    product.id !== newState.data.upsellingProducts[index].id
                    && product.isSelected
                    && product.bonusProducts.includes(bonus)
                ).length
                if (!otherSelectedProductHasSameBonus) {
                    let i = 0
                    while (i < newState.data.upsellingBonuses.length) {
                        if (newState.data.upsellingBonuses[i].id !== bonus)
                            i++
                        else {
                            newState.data.upsellingBonuses[i].isSelected = false
                            modifiedBonuses.push(newState.data.upsellingBonuses[i])
                            break
                        }
                    }
                }
            })
        }

        //Update products
        if (productIsSelected) {
            const newBonuses = modifiedBonuses.filter(bonus => {
                let keep = true
                let i = 0
                while (i < newState.data.products.length && keep) {
                    if (bonus.name === newState.data.products[i].name)
                        keep = false
                    else
                        i++
                }
                return keep
            })
            const modifiedUpsellingProducts = getFormattedUpsellingProducts([newState.data.upsellingProducts[index], ...newBonuses])
            newState.data.products = [...newState.data.products, ...modifiedUpsellingProducts]
        } else {
            const modifiedUpsellingProducts = [...modifiedBonuses, newState.data.upsellingProducts[index]]
            newState.data.products = newState.data.products.filter(product => {
                let i = 0
                while (i < newState.data.products.length) {
                    let j = 0
                    while (j < modifiedUpsellingProducts.length) {
                        if (product.name === modifiedUpsellingProducts[j].name)
                            return false
                        else
                            j++
                    }
                    i++
                }
                return true

            })

        }
        newState.data.products = newState.data.products.map(product => {
            if (product.amountWithDiscount !== undefined) product.amountWithDiscount = +product.amount
            return product
        })
        newState.promoCode = null
        this.setState(newState, this.CalculateTotal(newState.data.tipAmount))
    }

    toggleBonusIsSelected = (bonusDetails, bonusIndex, bonusIsSelected) => {
        const newState = { ...this.state }

        // Toggle bonus product selection
        newState.data.upsellingBonuses[bonusIndex].isSelected = !bonusIsSelected
        // If the bonus product is selected, the main products must be selected too
        const modifiedProducts = []
        if (!bonusIsSelected) {
            bonusDetails.productId.forEach(id => {
                newState.data.upsellingProducts.forEach(product => {
                    if (id === product.id) {
                        product.isSelected = true
                        modifiedProducts.push(product)
                    }
                })
            })
        }

        //Update products
        if (!bonusIsSelected) {
            //Add bonus and products that have this bonus (if not present yet)
            const newProducts = modifiedProducts.filter(product => {
                let keep = true
                let i = 0
                while (i < newState.data.products.length && keep) {
                    if (product.name === newState.data.products[i].name)
                        keep = false
                    else
                        i++
                }
                return keep
            })
            const modifiedUpsellingProducts = getFormattedUpsellingProducts([...newProducts, newState.data.upsellingBonuses[bonusIndex]])
            newState.data.products = [...newState.data.products, ...modifiedUpsellingProducts]
        } else {
            //Remove bonus
            newState.data.products = newState.data.products.filter(product => {
                let keep = true
                let i = 0
                while (i < newState.data.products.length && keep) {
                    if (product.name === newState.data.upsellingBonuses[bonusIndex].name)
                        keep = false
                    else
                        i++
                }
                return keep
            })
        }
        newState.data.products = newState.data.products.map(product => {
            if (product.amountWithDiscount !== undefined) product.amountWithDiscount = +product.amount
            return product
        })
        newState.promoCode = null
        this.setState(newState, this.CalculateTotal(newState.data.tipAmount))
    }


    checkPromoCode = async (promoCode) => {
        this.saveAndContinue()
        this.updateProducts()
        const StateCopy = { ...this.state }
        //Remove previous discount
        StateCopy.data.products = StateCopy.data.products.map(product => {
            if (product.amountWithDiscount !== undefined) product.amountWithDiscount = +product.amount
            return product
        })

        if (promoCode === '') {
            StateCopy.promoCode = null
            StateCopy.loader = false
            this.setState(StateCopy, this.CalculateTotal(StateCopy.data.tipAmount))
            return { isValid: undefined }
        }

        const payload = {
            promoCode,
            userEmail: StateCopy.data.email,
            stripeAccount: StateCopy.data.enrichHerAccount,
            productType: StateCopy.data.productType,
            items: StateCopy.data.products,
            prorationDate: StateCopy.prorationDate ?? Math.floor(Date.now() / 1000)
        }
        const promoCodeData = await checkPromotionCodeValidity(payload)

        if (promoCodeData.isValid) {
            let i = 0
            while (i < StateCopy.data.products.length) {
                if (StateCopy.data.products[i].stripeProductId === promoCodeData.productId) {
                    let productWithDiscount = +StateCopy.data.products[i].amount - promoCodeData.discount
                    productWithDiscount = productWithDiscount > 0 ? productWithDiscount : 0
                    StateCopy.data.products[i].amountWithDiscount = productWithDiscount
                    break
                }
                i++
            }

            StateCopy.promoCode = {
                promoCodeId: promoCodeData.promoCodeId,
                couponId: promoCodeData.couponId,
                subscriptionProrationDate: promoCodeData?.subscriptionProrationDate,
                discount: promoCodeData?.discount,
                productId: promoCodeData?.productId,
                promoCode
            }
        } else {
            StateCopy.promoCode = null
        }

        StateCopy.loader = false
        this.setState(StateCopy, this.CalculateTotal(StateCopy.data.tipAmount))
        return promoCodeData
    }

    render() {
        let hasError =
            this.state.saveMssg === "Please complete all the required fields"
            || this.state.saveMssg === ""
            || this.state.showSubmitUserError;
        let paypal = this.state.data.paymentType === "payPal";
        let ach = this.state.data.paymentType === "ach";
        let wallet = this.state.data.paymentType === "wallet";
        let token =
            this.state.data.paymentType === "credit_card" &&
            this.state.data.token === "";
        let disableButton =
            token || hasError
                ? true
                : this.state.data.paymentType === ""
                    ? true
                    : paypal && (hasError || !this.state.showPayPalBtn) ?
                        true : ach && (hasError || !this.state.showACHBtn)
        let {
            validInput_home_address,
            validInput_phone_number,
            validInput_home_zip_code,
            validInput_home_city,
            validInput_home_state,
            validInput_first_name,
            validInput_password,
            validInput_repeatPassword,
            validInput_last_name,
            validInput_email,
        } = this.state.validations;
        let {
            loader,
            showPhoneError,
            showSubmitError,
            errormsg,
            saveMssg,
            showSaveMessage,
            showSubmitUserError,
            submitUserErrorMsg,
            showModal,
            upsellType,
            promoCode,
            showPromoCodeInput
        } = this.state;
        const {
            email,
            first_name,
            last_name,
            subTotal,
            company,
            total,
            disableEmail,
            products,
            enrichHerAccount,
            productType,
            upsellingProducts,
            upsellingBonuses
        } = this.state.data;

        return (
            <div className="checkout-page">
                {loader && <div id="cover-spin"></div>}
                <Header />
                <Container className='container-ERH my-4'>
                    {showModal && <Modal title={modalWording.title} body={modalWording.body} btnText={modalWording.button} closeModal={this.closeModal} />}
                    <Row style={{ justifyContent: "center" }}>
                        <Col
                            xs={{ span: 12, order: 2 }}
                            sm={{ span: 12, order: 2 }}
                            lg={{ span: 7, order: 1 }}
                            className="accordion"
                        >
                            <Row><Col>
                                <Card>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            <div>
                                            Enter Billing address
                                                <OverlayTrigger
                                                    rootClose={true}
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            <div className='d-flex align-items-center billing-tooltip'>
                                                            <img width='120' height='100' src={BillingTooltipImage} />
                                                            <p>Please fill out <b>required fields in this section</b>, then click <b>save and continue to proceed</b>.</p>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <img className='mx-2' src={InterrogateIcon} alt='' id='billing_address' />
                                                </OverlayTrigger>
                                            </div>
                                            <img
                                                src={RowIcon}
                                                className="arrow-img"
                                                alt="collapse section icon"
                                                width="17px"
                                            />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Row className='p-2'>
                                                <Col xs={12} style={{ margin: "0 auto" }}>
                                                    <Row className="my-3" >
                                                        <Col xs={12} md={6}>
                                                            <ReusableInput
                                                                label='First Name*'
                                                                placeholder="First Name*"
                                                                name="first_name"
                                                                classInput={
                                                                    !validInput_first_name
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleValidation}
                                                                value={first_name}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <ReusableInput
                                                                label="Last Name*"
                                                                placeholder="Last Name*"
                                                                name="last_name"
                                                                classInput={
                                                                    !validInput_last_name
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleValidation}
                                                                value={last_name}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col xs={12} md={6}>
                                                            {disableEmail ? (
                                                                <ReusableInput
                                                                    label="E-mail*"
                                                                    placeholder="E-mail*"
                                                                    error={!validInput_email && "Please enter a valid email."}
                                                                    onChange={this.handleInputChange}
                                                                    name="email"
                                                                    onBlur={this.handleValidation}
                                                                    value={email}
                                                                    disabled
                                                                />
                                                            ) : (
                                                                <ReusableInput
                                                                    label="E-mail*"
                                                                    placeholder="E-mail*"

                                                                    error={!validInput_email && "Please enter a valid email."}
                                                                    onChange={this.handleInputChange}
                                                                    name="email"
                                                                    onBlur={this.handleValidation}
                                                                    value={email}
                                                                />
                                                            )}
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <ReusableInput
                                                                label='Phone number*'
                                                                placeholder="Phone number*"
                                                                error={!validInput_phone_number && "Please enter a valid phone number."}

                                                                onChange={this.handleInputChange}
                                                                name="phone_number"
                                                                onBlur={this.handleValidation}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="my-3">
                                                        <Col xs={12}>
                                                            <ReusableInput
                                                                label="Street Address*"
                                                                placeholder="Street Address*"
                                                                classInput={
                                                                    !validInput_home_address
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                name="home_address"
                                                                onBlur={this.handleValidation}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col xs={12}>
                                                            <ReusableInput
                                                                label='Street Address 2'
                                                                placeholder="Street Address 2"
                                                                onChange={this.handleInputChange}
                                                                name="home_address_2"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col xs={12} md={6}>
                                                            <ReusableInput
                                                                label='City*'
                                                                placeholder="City*"
                                                                classInput={
                                                                    !validInput_home_city
                                                                        ? "error "
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                name="home_city"
                                                                onBlur={this.handleValidation}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={2}>
                                                            <ReusableInput
                                                                label="State*"
                                                                placeholder="State*"
                                                                classInput={
                                                                    !validInput_home_state
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                name="home_state"
                                                                onBlur={this.handleValidation}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <ReusableInput
                                                                label='Zipcode*'
                                                                placeholder="Zipcode*"
                                                                classInput={
                                                                    !validInput_home_state
                                                                        ? "error"
                                                                        : ""
                                                                }
                                                                onChange={this.handleInputChange}
                                                                name="home_zip_code"
                                                                onBlur={this.handleValidation}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p
                                                                className={`error-message ${showSaveMessage === false ? "hidden" : "true"
                                                                    }`}
                                                                id="error-message-api"
                                                            >
                                                                {saveMssg}
                                                            </p>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-end'>
                                                            <Button
                                                                style={{ width: "15.375rem" }}
                                                                round
                                                                className='mt-4'
                                                                type="submit"
                                                                onClick={this.saveAndContinue}
                                                            >
                                                                Save and continue
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Card>
                            </Col></Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <Accordion defaultActiveKey="1">
                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                <div>
                                                    Payment information
                                                        <OverlayTrigger
                                                        rootClose={true}
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id="button-tooltip">
                                                                <div className='d-flex align-items-center payment-tooltip'>
                                                                    <img src={PaymentTooltipImage} width='140' height='120' />
                                                                    <p>Enter your <b>card information</b> along with <b>expiration date and CVC</b> or <b>alternate payment methods</b> such as Google Pay, 
                                                                    Apple Pay or Microsoft Pay, then click <b>save and continue</b> to proceed. If you have a <b>promo code</b>, 
                                                                    enter it in the appropiate section under <b>Order Summary</b>.</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        >
                                                            <img className='mx-2' src={InterrogateIcon} alt='' id='payment_information' /> 
                                                        </OverlayTrigger>
                                                </div>
                                                <img
                                                    src={RowIcon}
                                                    className="arrow-img"
                                                    alt="collapse section icon"
                                                    width="17px"
                                                />
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Row className='p-2'>
                                                    <Col xs={12} style={{ margin: "0 auto" }}>
                                                        <Row className="margin-inputs">
                                                            <Col xs={12} md={12}>
                                                                <div
                                                                    className={
                                                                        this.state.data.paymentType === "credit_card"
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                    style={{ marginTop: 8 }}
                                                                >
                                                                    <ERHRadioBtn
                                                                        onChange={this.handleInputChange}
                                                                        name="paymentType"
                                                                        type="radio"
                                                                        label={<>    <strong>
                                                                            Credit Card{" "}
                                                                            <span className="mobile-hidden">
                                                                                (Fees Apply)
                                                                            </span>
                                                                        </strong>
                                                                            <img
                                                                                src={creditCardLogos}
                                                                                className="cards-imag"
                                                                                alt="credit card logos(Visa, Master, Amex and Discover)"
                                                                            /></>}
                                                                        checked={
                                                                            this.state.data.paymentType === "credit_card"
                                                                        }
                                                                        id="credit_card"
                                                                        value="credit_card"
                                                                    />

                                                                    <hr />
                                                                </div>
                                                                {this.state.data.paymentType === "credit_card" && (
                                                                    <Elements stripe={stripePromise}>
                                                                        <StripeSection handleToken={this.setToken} />
                                                                    </Elements>
                                                                )}
                                                                <div
                                                                    className={
                                                                        this.state.data.paymentType === "wallet"
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <ERHRadioBtn
                                                                        onChange={this.handleInputChange}
                                                                        name="paymentType"
                                                                        placeholder=""
                                                                        label={<>
                                                                            <img
                                                                                src={walletLogos}
                                                                                className="wallet-imag"
                                                                                alt="wallets logo"
                                                                            />
                                                                        </>}
                                                                        id="wallet"
                                                                        value="wallet"
                                                                        checked={
                                                                            this.state.data.paymentType === "wallet"
                                                                        }
                                                                    />
                                                                    <hr />
                                                                </div>
                                                                {productType === "products" && (
                                                                    <>
                                                                        <div
                                                                            className={this.state.data.paymentType === "ach" ? 'active' : ''}>
                                                                            <ERHRadioBtn
                                                                                onChange={this.handleInputChange}
                                                                                name="paymentType"
                                                                                label={<strong>ACH</strong>}
                                                                                id="ach"
                                                                                value="ach"
                                                                                checked={this.state.data.paymentType === "ach"}
                                                                            />
                                                                            <hr />
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                this.state.data.paymentType === "payPal"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <ERHRadioBtn
                                                                                onChange={this.handleInputChange}
                                                                                name="paymentType"
                                                                                placeholder=""
                                                                                label={<>
                                                                                    <img
                                                                                        src={paypalLogo}
                                                                                        alt="paypal logo"
                                                                                        height={60}
                                                                                    />

                                                                                    <img
                                                                                        src={creditCardLogos}
                                                                                        className="cards-imag"
                                                                                        alt=" credit card logos(Visa, Master, Amex and Discover)"
                                                                                    />
                                                                                </>}
                                                                                id="payPal"
                                                                                value="payPal"
                                                                                checked={
                                                                                    this.state.data.paymentType === "payPal"
                                                                                }
                                                                            />

                                                                            <hr />
                                                                        </div>

                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} ref={this.myRef} className='d-flex justify-content-end' id="payment-info-footer">
                                                                {showSubmitUserError &&
                                                                    <p className='error-input create-user-error'>{submitUserErrorMsg}</p>
                                                                }
                                                                <Button
                                                                    style={{ width: "15.375rem" }}
                                                                    className='mt-4'
                                                                    onClick={(e) => {
                                                                        this.saveAndContinue(e);
                                                                        this.setState((state) => ({ ...state, loader: true }), this.createWordPressUser)
                                                                        this.showButton(e);
                                                                    }}
                                                                    type="submit"
                                                                    form={
                                                                        this.state.data.paymentType === "credit_card"
                                                                            ? "payment-form"
                                                                            : this.state.data.paymentType === "ach"
                                                                                ? "ach-form"
                                                                                : this.state.data.paymentType === "payPal"
                                                                                    ? "paypal_form"
                                                                                    : ""
                                                                    }
                                                                >
                                                                    Save and continue
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 12, order: 1 }}
                            lg={{ span: 5, order: 2 }}
                        >
                            <PaymentSummary
                                email={email}
                                company={company}
                                handlePayment={this.handlePayment}
                                title="ORDER SUMMARY"
                                products={products}
                                setLoader={value => this.setState({ loader: value })}
                                handleToken={this.setToken}
                                total={total}
                                disableButton={disableButton}
                                isDonation={this.state.isDonation}
                                name={first_name + " " + last_name}
                                subTotal={subTotal}
                                calculateTip={this.CalculateTotal}
                                paypal={paypal}
                                ach={ach}
                                wallet={wallet}
                                stripeAccount={enrichHerAccount}
                                handleDonationCallback={this.handleDonationCallback}
                                callback={this.handleCallback}
                                type={productType}
                                updateProducts={this.updateProducts}
                                upsellType={upsellType}
                                toggleProductIsSelected={this.toggleProductIsSelected}
                                toggleBonusIsSelected={this.toggleBonusIsSelected}
                                upsellingProducts={upsellingProducts}
                                upsellingBonuses={upsellingBonuses}
                                checkPromoCode={this.checkPromoCode}
                                promoCode={promoCode}
                                showPromoCodeInput={showPromoCodeInput}
                                stripePromise={stripePromise}
                            />
                            <Col>
                                <p
                                    className={`error-message text-center ${showSubmitError === false ? "hidden" : "true"
                                        }`}
                                    id="error-message-api"
                                >
                                    {errormsg}
                                </p>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-sm-block d-lg-none">
                            <Card className="payment-summary">
                                <ProductsSummary
                                    products={products}
                                    subTotal={subTotal}
                                    dontShowProducts={true}
                                    disablePromoCode={disableButton}
                                    showDiscount={!!promoCode?.discount}
                                />
                                {productType === "products" && (
                                    <TipSection
                                        subTotal={subTotal}
                                        tip={total - subTotal}
                                        calculateTip={this.CalculateTotal}
                                    />
                                )}
                                {showPromoCodeInput &&
                                    <PromotionCode
                                        checkPromoCode={this.checkPromoCode}
                                        setLoader={value => this.setState({ loader: value })}
                                        disablePromoCode={disableButton}
                                        showDiscount={!!promoCode?.discount}
                                    />
                                }
                                <PurchaseTotal total={total} />
                                <UpsellingProducts
                                    purchaseEnabled={!disableButton}
                                    upsellingProducts={upsellingProducts}
                                    upsellingBonuses={upsellingBonuses}
                                    toggleProductIsSelected={this.toggleProductIsSelected}
                                    toggleBonusIsSelected={this.toggleBonusIsSelected}
                                />
                                <CompletePurchaseSection
                                    updateProducts={this.updateProducts}
                                    products={products}
                                    handleToken={this.setToken}
                                    total={total}
                                    subTotal={subTotal}
                                    handlePayment={this.handlePayment}
                                    setLoader={value => this.setState({ loader: value })}
                                    email={email}
                                    name={first_name + " " + last_name}
                                    paypal={paypal}
                                    ach={ach}
                                    wallet={wallet}
                                    stripeAccount={enrichHerAccount}
                                    isDonation={this.state.isDonation}
                                    callback={this.handleCallback}
                                    handleDonationCallback={this.handleDonationCallback}
                                    company={company}
                                    disableButton={disableButton}
                                    upsellType={upsellType}
                                    stripePromise={stripePromise}
                                />
                            </Card>
                            <p
                                className={`error-message text-center ${showSubmitError === false ? "hidden" : "true"
                                    }`}
                                id="error-message-api"
                            >
                                {errormsg}
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        );
    }
}


//Checkout.contextType = UpsellingProductsContext;