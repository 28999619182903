import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatAmount } from '../helpers'


const PurchaseTotal = ({ total }) =>
    <Row className='complete-purchase purchase-total'>
        <Col xs={12} className='d-flex justify-content-between flex-wrap flex-grow-1 my-1'>
            <p className='p-0 text-total'> Total </p>
            <p className='p-0 text-total'> <b> ${formatAmount(total)} </b> </p>
        </Col>
    </Row>


export default PurchaseTotal
