import React from 'react'
import TopFooterConnectWithUs from './TopFooterConnectWithUs'
import TopFooterItem from './TopFooterItem'



const TopFooterColumn = ({ column }) => {
    const [columnTitle, columnOptions] = column
    const columnValue = columnTitle === 'CONNECT WITH US' ?
            <TopFooterConnectWithUs values={columnOptions} />
        :
            <p>
                {columnOptions.map(option =>
                    option.title ?
                        <TopFooterItem
                            key={option.title}
                            title={option.title}
                            url={option.url}
                        />
                        :
                        option
                )}
            </p>
            

    return (
        <div className='footer_col'>
            <h5 className='my-3 p-0' id='footer-column-title'>{columnTitle}</h5>
            {columnValue}
            
        </div>
    )
}

export default TopFooterColumn
