import React from 'react';


export const ERHRadioBtn = ({
    label,
    classContainer = '',
    negative = false,
    classLabel = '',
    className = '',
    type,
    ...rest
}) => {

    return (
        <label htmlFor={rest.id} className={`container-radio-btn ${classContainer} ${negative ? 'negative' : ''}`}>

            <input className={`ERH-radio-btn ${className}`} type='radio' {...rest} />
            {label && <label className={`label-radio-btn ${classLabel}`} htmlFor={rest.id}> {label} </label>}
            <div className='borders' />
        </label>
    );
};
