import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Logo from '../Assets/logos/logo-desktop.svg'
export class Header extends Component {


    render() {
        return (
            <>
                <nav className="navbar" style={{ backgroundColor: '#FFFFFF' }}>
                    <div className='navbar-inner'>
                        <a href='https://enrichher.com/'><img
                            src={Logo}
                            alt="EnrichHer Logo" width="150px"
                        /></a>
                    </div>
                </nav>
            </>
        )
    }

}