import React from 'react'
import FacebookIcon from '../../Assets/Icons/facebook.svg'
import InstagramIcon from '../../Assets/Icons/instagram.svg'
import LinkedinIcon from '../../Assets/Icons/linkedin.svg'
import TwitterIcon from '../../Assets/Icons/twitter.svg'
import YoutubeIcon from '../../Assets/Icons/youtube.svg'

const socialMediaIcons = [
    {
        icon: TwitterIcon,
        url: 'https://twitter.com/ienrichher'
    },
    {
        icon: FacebookIcon,
        url: 'https://www.facebook.com/ienrichher'
    },
    {
        icon: InstagramIcon,
        url: 'https://www.instagram.com/ienrichher'
    },
    {
        icon: LinkedinIcon,
        url: 'https://www.linkedin.com/company/ienrichher'
    },
    {
        icon: YoutubeIcon,
        url: 'https://www.youtube.com/channel/UCzo96rD6-tbQjCVwooD-SHA'
    }
]

const TopFooterSocialMedia = () => {
    return (
        <div className='footer-social-media'>
            {socialMediaIcons.map(item => 
                <a key={item.url} href={item.url} target='_blank'>
                    <img key={item.icon} src={item.icon} href={item.url} />
                </a>
            )}
        </div>
    )
}

export default TopFooterSocialMedia
